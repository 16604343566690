import { NgModule } from '@angular/core';
import { AppComponent } from './root/app.component';
import { components } from './app.components';
import { services } from './app.services';
import { imports } from './app.imports';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DecoratorService } from './services/decorator.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LinkifyPipe } from './pipes/linkify.pipe';

@NgModule({
  imports: [imports, ReactiveFormsModule, FormsModule, MatDialogModule],
  declarations: [components, LinkifyPipe],
  providers: [services, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(decoratorService: DecoratorService) {}
}
