export class SupportTicket {
  id: number;
  email: string;
  status: string;
  taskId: number;
  topic: string;
  subTopic: string;
  mainConcern: string;
  detailConcern: string;
  stripeChargeId: string;
  stripePayoutId: string;
  userId: number;
  media: [];

  createdAt: string;
  updatedAt: string;
  viewedBy: any;

  constructor(data: any = null) {
    Object.assign(this, data);
  }
}
