import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  Input,
  OnInit,
  AfterViewChecked,
  ViewChildren,
  QueryList,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Offer } from 'src/app/models/offer.model';
import { OfferComment } from 'src/app/models/offerComment.model';
import { Task } from 'src/app/models/task.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { TaskService } from 'src/app/services/task.service';
import { UserService } from 'src/app/services/user.service';
import { OfferService } from 'src/app/services/offer.service';
import { Comment } from 'src/app/models/comment.model';
import { format } from 'date-fns';
import { CensorEntryDialogComponent } from '../dialogs/censor-entry/censorEntryDialog.component';
import { MiscService } from 'src/app/services/misc.service';
import { BanUserDialogComponent } from '../dialogs/ban-user/banUserDialog.component';
import { AdminTaskConversationCommentDialog } from '../dialogs/adminTaskConversationCommentDialog/adminTaskConversationCommentDialog.component';
import { OfferNegotiationDialogComponent } from '../dialogs/offerNegotiationDialog/offerNegotiationDialog.component';
import { ActivatedRoute } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CommentService } from 'src/app/services/comment.service';

type CensorType = 'offer' | 'offer-comment' | 'task-comment';
@Component({
  selector: 'app-task-conversation',
  templateUrl: './task-conversation.component.html',
  styleUrls: ['./task-conversation.component.scss'],
})
export class TaskConversationComponent
  implements OnInit, AfterViewChecked, OnChanges
{
  @Input() inputType: string;
  @Input() inputTypeId: number;
  @ViewChildren('offerElements') offerElements: QueryList<MatExpansionPanel>;
  @ViewChildren('taskCommentElements')
  taskCommentElements: QueryList<MatExpansionPanel>;
  public comment: Comment;
  public taskComments: { [key: number]: Comment[] };
  public offers: Offer[];
  public offerComments: { [key: number]: OfferComment[] };
  public user: User;
  public users: { [key: number]: User };
  public id: number;
  public type: string = 'task';
  public expandedOfferId: number;
  public expandedThreadId: number;
  public taskCommentsExist: boolean;
  public task: Task;
  public highlightedEntry: number;

  constructor(
    private offerService: OfferService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private deviceDetector: DeviceDetectorService,
    private taskService: TaskService,
    private miscService: MiscService,
    private commentService: CommentService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputType || changes.inputTypeId) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    switch (this.inputType) {
      case 'offer':
        this.type = 'offer';
        this.id = this.inputTypeId;
        break;
      case 'offer-comment':
        this.type = 'offer-comm';
        this.id = this.inputTypeId;
        break;
      case 'task':
        this.type = 'task';
        this.id = this.inputTypeId;
        break;
      case 'task-comment':
        this.type = 'task-comm';
        this.id = this.inputTypeId;
        break;
      default:
        break;
    }
    this.setFocus(this.type, this.id);
  }

  ngAfterViewChecked(): void {
    if (this.inputType && this.inputTypeId) {
      this.highlightData(this.inputType, parseInt(this.inputTypeId.toString()));
    }
  }

  setFocus(type: string, id: number): void {
    if (!type || !id) {
      return;
    }
    this.miscService.getTaskAndOffersAndComments(type, id.toString()).subscribe(
      (res) => {
        if (res) {
          this.task = res.task;
          const sortedOffers = res.offers.sort(
            (a: Offer, b: Offer) => a.id - b.id,
          );
          this.offers = sortedOffers;
          this.offers.forEach((offer) => {
            if (
              offer.status !== 'pending' &&
              offer.status !== 'rejected' &&
              offer.status !== 'erased'
            )
              this.offerService.getPriceIncOffer(offer.id).subscribe((res) => {
                if (res && res.length > 0) {
                  offer.offerPriceIncreases = res;
                }
              });
          });
          this.offerComments = this.groupOfferComments(
            res.offerComments,
            res.offers,
          );
          this.taskComments = this.groupTaskComments(res.taskComments);
          this.userService.getMultipleUsers(this.extractUserIds(res)).subscribe(
            (res) => (this.users = this.groupUsers(res)),
            (err) =>
              this.authService.handleError(
                err,
                'Failed to retrive multiple users',
              ),
          );
        }
      },
      (err) => this.authService.handleError(err, 'Failed to retrieve data'),
    );
  }

  groupOfferComments(
    comments: OfferComment[],
    offers: Offer[],
  ): { [key: number]: OfferComment[] } {
    const offerComments = {};
    offers.forEach((offer) => {
      if (!offerComments[offer.id]) {
        offerComments[offer.id] = [];
      }
      offerComments[offer.id] = comments
        .filter((comment) => comment.offerId == offer.id)
        .sort((a: OfferComment, b: OfferComment) => a.id - b.id);
    });
    return offerComments;
  }

  groupTaskComments(comments: Comment[]): { [key: number]: Comment[] } {
    const taskComments = {};
    comments.forEach((comment) => {
      if (!taskComments[comment.parentId]) {
        taskComments[comment.parentId] = [];
      }
      taskComments[comment.parentId] = [
        ...taskComments[comment.parentId],
        comment,
      ].sort((a: Comment, b: Comment) => a.id - b.id);
    });
    if (taskComments[0]) {
      this.taskCommentsExist = true;
    } else {
      this.taskCommentsExist = false;
    }
    return taskComments;
  }

  getOfferIdFromOfferCommentId(id: number): number {
    var offerId = -1;
    if (this.offerComments) {
      const offerComments = Object.values(this.offerComments);
      offerComments.forEach((commentGroup) => {
        commentGroup.forEach((comment) => {
          if (comment.id === parseInt(id.toString())) {
            offerId = comment.offerId;
          }
        });
      });
    }
    return offerId;
  }

  getParentIdFromTaskCommentId(id: number): number {
    var taskCommentId = -1;
    if (this.taskComments) {
      const taskComments = Object.values(this.taskComments);
      taskComments.forEach((commentGroup) => {
        commentGroup.forEach((comment) => {
          if (comment.id === parseInt(id.toString())) {
            taskCommentId = comment.parentId;
          }
        });
      });
    }
    return taskCommentId;
  }

  extractUserIds(res: any): number[] {
    const ids: Set<number> = new Set();
    ids.add(res.task.userId);

    const findUserIds = (data: any[]): void => {
      data.forEach((element) => ids.add(element.userId));
    };
    findUserIds(res.offers);
    findUserIds(res.offerComments);
    findUserIds(res.taskComments);
    return Array.from(ids);
  }

  groupUsers(users: User[]): { [key: number]: User } {
    const groupedUsers = [];
    users.forEach((user) => {
      if (!groupedUsers[user.id]) {
        groupedUsers[user.id] = user;
      }
    });
    return groupedUsers;
  }

  addHtmlClass(comment: Comment | OfferComment, offer?: Offer): string {
    if (comment?.censored != null || offer?.censored != null) {
      if (offer && offer.userId === comment.userId) {
        return 'pinployee-censored';
      } else if (this.task.userId === comment.userId) {
        return 'task-owner-censored';
      }
      return 'other-user-censored';
    } else if (offer && offer.userId === comment.userId) {
      return 'pinployee';
    } else if (this.task.userId === comment.userId) {
      return 'task-owner';
    }
    return 'other-user';
  }

  convertTimestamp(ts: string): string {
    return format(new Date(ts), 'dd-MM-yyyy kk:mm:ss');
  }

  openNegotiaton(offerId: number, handyhanderId: number, event: MouseEvent) {
    const taskOwnerId = this.task.userId;
    this.dialog
      .open(OfferNegotiationDialogComponent, {
        width: '80%',
        height: '80%',
        data: { offerId, handyhanderId, taskOwnerId },
      })
      .afterClosed();

    event.stopPropagation();
  }

  openCensor(type: CensorType, id: number, event: MouseEvent) {
    // check if the event is a click event and if windows / cmd key is pressed
    if (event.metaKey || event.ctrlKey) {
      let data: any;
      switch (type) {
        case 'offer':
          data = {};
          this.offerService.getOfferById(id).subscribe({
            next: (res) => {
              if (res.censored) {
                data.censored = null;
              } else {
                data.censored = new Date();
              }
              data.offerId = id;
              this.offerService.realEditOffer(id, data).subscribe({
                next: (res) => {
                  this.updateEntry(type, id, data);
                },
                error: (err) => {
                  this.authService.handleError(err, 'Failed to retrieve offer');
                },
              });
            },
            error: (err) => {
              this.authService.handleError(err, 'Failed to retrieve offer');
            },
          });
          break;
        case 'offer-comment':
          data = {};
          this.commentService.getOfferCommentById(id).subscribe({
            next: (res) => {
              if (res.censored) {
                data.censored = null;
              } else {
                data.censored = new Date();
              }
              data.commentId = id;
              this.commentService.editOfferComment(id, data).subscribe({
                next: (res) => {
                  this.updateEntry(type, id, data);
                },
                error: (err) => {
                  this.authService.handleError(err, 'Failed to retrieve offer');
                },
              });
            },
            error: (err) => {
              this.authService.handleError(err, 'Failed to retrieve offer');
            },
          });
          break;
        case 'task-comment':
          data = {};
          this.commentService.getTaskCommentById(id).subscribe({
            next: (res) => {
              if (res.censored) {
                data.censored = null;
              } else {
                data.censored = new Date();
              }
              data.commentId = id;
              this.commentService.editTaskComment(id, data).subscribe({
                next: (res) => {
                  this.updateEntry(type, id, data);
                },
                error: (err) => {
                  this.authService.handleError(err, 'Failed to retrieve offer');
                },
              });
            },
            error: (err) => {
              this.authService.handleError(err, 'Failed to retrieve offer');
            },
          });
          break;
        default:
          break;
      }
      event.stopPropagation();
      return;
    }
    event.stopPropagation();
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog
      .open(CensorEntryDialogComponent, {
        width: width,
        data: { type, id },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.updateEntry(type, id, res);
        }
      });
  }

  openBanUserDialog(userId: number, type: string, typeId: number) {
    this.dialog
      .open(BanUserDialogComponent, {
        width: '80%',
        data: { userId, type, typeId },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
        }
      });
  }

  updateEntry(type: CensorType, id: number, data: any) {
    let entry: any;
    switch (type) {
      case 'offer':
        entry = this.offers.find((item: any) => item.id === id);
        break;
      case 'offer-comment':
        const offerComments = Object.values(this.offerComments);
        for (let i = 0; i < offerComments.length; i += 1) {
          const comment = offerComments[i].find((comment) => comment.id === id);
          if (comment) {
            entry = comment;
            break;
          }
        }
        break;
      case 'task-comment':
        const taskComments = Object.values(this.taskComments);
        for (let i = 0; i < taskComments.length; i += 1) {
          const comment = taskComments[i].find((comment) => comment.id === id);
          if (comment) {
            entry = comment;
            break;
          }
        }
        break;
      default:
        throw new Error('Unknown entity type');
    }

    if (!entry) {
      throw new Error('Could not find entry');
    }

    const updatedText = type === 'offer' ? data.message : data.text;

    if (updatedText) {
      entry[type === 'offer' ? 'message' : 'text'] = updatedText;
    }

    if (data.censored !== undefined) {
      entry.censored = data.censored;
    }
  }

  highlightData(type: string, id: number): void {
    switch (type) {
      case 'task':
        break;
      case 'offer':
        this.offerElements.changes.subscribe((elements) => {
          if (elements.length > 0) {
            elements.forEach((element) => {
              if (
                `offer${this.inputTypeId}` === `${element.nativeElement.id}`
              ) {
                setTimeout(
                  () =>
                    element.nativeElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    }),
                  500,
                );
              }
            });
          }
        });
        break;
      case 'offer-comment':
        this.offerElements.changes.subscribe((elements) => {
          if (elements.length > 0) {
            elements.forEach((element) => {
              if (
                `offer${this.inputTypeId}` === `${element.nativeElement.id}`
              ) {
                setTimeout(
                  () =>
                    element.nativeElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    }),
                  500,
                );
              }
            });
          }
        });
        break;
      case 'task-comment':
        this.taskCommentElements.changes.subscribe((elements) => {
          if (elements.length > 0) {
            elements.forEach((element) => {
              if (
                `taskComment${this.inputTypeId}` ===
                `${element.nativeElement.id}`
              ) {
                setTimeout(
                  () =>
                    element.nativeElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    }),
                  500,
                );
              } else if (
                `thread${this.inputTypeId}` === `${element.nativeElement.id}`
              ) {
                setTimeout(
                  () =>
                    element.nativeElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    }),
                  500,
                );
              }
            });
          }
        });
        break;
      default:
        throw new Error('Unknown type');
    }
  }

  openAdminOfferComment(type: string, data: any) {
    this.dialog
      .open(AdminTaskConversationCommentDialog, {
        width: '80%',
        data: { type, ...data },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.type === 'offer') {
            const adminOfferComment = new OfferComment(res);
            this.offerComments[adminOfferComment.offerId] = [
              ...this.offerComments[adminOfferComment.offerId],
              adminOfferComment,
            ];
          } else {
            const adminTaskComment = new Comment(res);
            if (!this.taskComments[adminTaskComment.parentId]) {
              this.taskComments[adminTaskComment.parentId] = [];
            }
            this.taskComments[adminTaskComment.parentId] = [
              ...this.taskComments[adminTaskComment.parentId],
              adminTaskComment,
            ];
          }
        }
      });
  }
}
