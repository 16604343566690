<div>
  <h3 class="header">Users active tasks</h3>
</div>
<div class="dialog_container">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="openUserTasks.length !== 0"
      label="Open Tasks {{ openUserTasks.length }}"
    >
      <div
        class="dialog_row_container"
        *ngFor="let tasker of openUserTasks; let i = index"
      >
        <div class="dialog_column_container">
          <div class="task_card_container">
            <app-task-card
              [task]="tasker"
              (closeDialogEvent)="closeDialogEvent($event)"
            ></app-task-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      *ngIf="ongoingUserTasks.length !== 0"
      label="Ongoing tasks {{ ongoingUserTasks.length }}"
    >
      <div
        class="dialog_row_container"
        *ngFor="let tasker of ongoingUserTasks; let i = index"
      >
        <div class="dialog_column_container">
          <div class="task_card_container">
            <app-task-card
              [task]="tasker"
              (closeDialogEvent)="closeDialogEvent($event)"
            ></app-task-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      *ngIf="completedUserTasks.length !== 0"
      label="Completed Tasks {{ completedUserTasks.length }}"
    >
      <div
        class="dialog_row_container"
        *ngFor="let tasker of completedUserTasks; let i = index"
      >
        <div class="dialog_column_container">
          <div class="task_card_container">
            <app-task-card
              [task]="tasker"
              (closeDialogEvent)="closeDialogEvent($event)"
            ></app-task-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      *ngIf="cancelledUserTasks.length !== 0"
      label="Cancelled Tasks {{ cancelledUserTasks.length }}"
    >
      <div
        class="dialog_row_container"
        *ngFor="let tasker of cancelledUserTasks; let i = index"
      >
        <div class="dialog_column_container">
          <div class="task_card_container">
            <app-task-card
              [task]="tasker"
              (closeDialogEvent)="closeDialogEvent($event)"
            ></app-task-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      *ngIf="expiredUserTasks.length !== 0"
      label="Expired Tasks {{ expiredUserTasks.length }}"
    >
      <div
        class="dialog_row_container"
        *ngFor="let tasker of expiredUserTasks; let i = index"
      >
        <div class="dialog_column_container">
          <div class="task_card_container">
            <app-task-card
              [task]="tasker"
              (closeDialogEvent)="closeDialogEvent($event)"
            ></app-task-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      *ngIf="erasedUserTasks.length !== 0"
      label="Erased Tasks {{ erasedUserTasks.length }}"
    >
      <div
        class="dialog_row_container"
        *ngFor="let tasker of erasedUserTasks; let i = index"
      >
        <div class="dialog_column_container">
          <div class="task_card_container">
            <app-task-card
              [task]="tasker"
              (closeDialogEvent)="closeDialogEvent($event)"
            ></app-task-card>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
