import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-users-currently-viewing',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './users-currently-viewing.component.html',
  styleUrl: './users-currently-viewing.component.scss',
})
export class UsersCurrentlyViewingComponent {
  constructor() {}

  @Input({ required: true }) usersCurrentlyViewing: User[];
}
