import { User } from './user.model';
import { Task } from './task.model';

export class Offer {
  id: number;
  status: string;
  message: string;
  stripeChargeId: string;
  priceBid: number;
  isPaid: boolean;

  taskId: number;
  task: Task;

  userId: number;
  user: User;

  createdAt: string;
  updatedAt: string;

  offerPriceIncreases?: any;
  acceptedAt?: string;
  completedAt?: any;
  cancelledAt?: any;

  censored: Date;

  taskOwnerFee: number;

  constructor(data: any = null) {
    Object.assign(this, data);

    if (!this.user) {
      this.user = new User();
    }
    if (!this.task) {
      this.task = new Task();
    }
  }
}
