import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SocketService } from '../services/socket.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private socketService: SocketService,
    private storage: StorageService,
    private router: Router) {}

  showComponent() {
    return this.router.url !== '/login';
  }

  ngOnInit() {
    const socketId = Math.random().toString(36).substring(2, 15);
    this.storage.socketId = socketId;
    this.socketService.connectSocket();
  }

}
