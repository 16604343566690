<div class="dialog_container">
  <i
    class="material-icons exit_icon"
    (click)="close()"
    >close</i
  >
  <h3 [innerHtml]="title"></h3>
  <p [innerHtml]="description"></p>

  @if (showCheckBox){
  <div style="margin-bottom: 25px; width: 100%">
    <mat-checkbox
      color="primary"
      (change)="checkBox($event.checked)"
    >
      {{ checkBoxQuestion }}
    </mat-checkbox>
  </div>
  }
  <div class="buttons">
    <button
      class="pinploy_button small_button red_button"
      (click)="apply()"
    >
      Yes
    </button>
    <button
      class="pinploy_button small_button"
      (click)="close()"
    >
      No
    </button>
  </div>
</div>
