<div class="support-ticket-container">
  <div class="header">
    <h1>Support Ticket: ({{ supportTicket.status }})</h1>
    <div>
      <div
        *ngIf="supportTicket.stripePayoutId"
        class="pay-issue-container"
      >
        <h4>
          Payout Issue:
          <p
            class="pay-issue"
            (click)="navigateTo('payout', supportTicket.stripePayoutId)"
          >
            {{ supportTicket.stripePayoutId }}
          </p>
        </h4>
      </div>
      <div
        *ngIf="supportTicket.stripeChargeId"
        class="pay-issue-container"
      >
        <h4>
          Payment Issue:
          <p
            class="pay-issue"
            (click)="navigateTo('charge', supportTicket.stripeChargeId)"
          >
            {{ supportTicket.stripeChargeId }}
          </p>
        </h4>
      </div>
    </div>
    <div class="users-currently-viewing">
      @if (usersCurrentlyViewing?.length > 0) {
      <h3 class="info">Currently viewing:</h3>
      <app-users-currently-viewing
        [usersCurrentlyViewing]="usersCurrentlyViewing"
      />
      }
    </div>
  </div>

  <div class="body">
    <div class="top">
      <!-- GRID BOX 1, bottom, user information area-->
      <div
        class="left"
        *ngIf="supportUser.id"
      >
        <app-user-card
          [title]="'User details'"
          [user]="supportUser"
          [inclStripeCustomer]="true"
          [inclStripeConnect]="true"
        ></app-user-card>

        <div *ngIf="task.id || supportTicket.taskId">
          <app-task-card
            [title]="'Task details'"
            [task]="task"
          >
          </app-task-card>
        </div>
      </div>
      <div
        class="left-alt"
        *ngIf="!supportUser.id"
      >
        <p>This user wasn't logged in</p>
        <br />
        <p><b>Email:</b> {{ supportTicket.email }}</p>
      </div>

      <!-- GRID BOX 2, bottom, prior inquiries-->
      <div class="right">
        <h4>User Inqueries</h4>
        <p style="font-size: 12px">
          List of all Support Tickets Inqueries by the user in the past and
          status.
        </p>
        <div class="prior-inquiries">
          <ul class="list-inquiries">
            <li
              *ngFor="
                let prevSupportTick of prevUserSupportTickets
                  | slice : stepOverTicketIndex
              "
              (click)="navigateTo('previously-inqueries', prevSupportTick.id)"
              [ngClass]="{
                active_inquiry: prevSupportTick.id === supportTicket.id
              }"
            >
              <p [ngStyle]="{ color: getStatusColor(prevSupportTick.status) }">
                - [{{ prevSupportTick.status }}]
              </p>
              &#160;&#160;{{ prevSupportTick.mainConcern.substring(0, 30) }} -
              {{ prevSupportTick.createdAt | date }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="middle">
      <div class="inquery-container">
        <div class="inquiry-message">
          <h3 *ngIf="supportTicket.mainConcern">
            {{ supportTicket.mainConcern }}
          </h3>
          <h4 *ngIf="supportTicket.subTopic">
            Area: {{ supportTicket.subTopic }}
          </h4>
          <i>
            Date: {{ supportTicket.createdAt | date : 'dd / MM / yyyy, HH:mm' }}
          </i>
          <textarea
            readonly
            class="inquiry border-field"
            [value]="supportTicket.detailConcern"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10"
          ></textarea>
        </div>
        <div
          *ngIf="supportTicket.media && supportTicket.media.length > 0"
          class="media"
        >
          <h3>Attached Documents and images</h3>
          <div class="media-box">
            <div
              *ngFor="
                let img of showAllMedia
                  ? supportTicket.media
                  : (supportTicket.media | slice : 0 : 3)
              "
            >
              <app-image-preview
                *ngIf="!img.isPdf"
                [imageUrl]="img.url"
                class="support-img"
              ></app-image-preview>
              <app-image-preview
                *ngIf="img.isPdf"
                [pdfUrl]="img.url"
                class="support-img"
              ></app-image-preview>
            </div>
          </div>
          <div class="center">
            <button
              class="pinploy_button small_button"
              (click)="showAllMedia = !showAllMedia"
              *ngIf="supportTicket.media.length > 3 && !showAllMedia"
            >
              Show all
            </button>
            <button
              class="pinploy_button small_button"
              (click)="showAllMedia = !showAllMedia"
              *ngIf="supportTicket.media.length > 3 && showAllMedia"
            >
              Show less
            </button>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
    <br />
    <div class="bottom">
      <div class="bottom-header">
        <div class="left">
          <h3>Response</h3>
        </div>
        <div class="right"></div>
      </div>
      <body class="body">
        <div class="response">
          <div *ngIf="supportTicket.status == 'Open'">
            <input
              class="border-field phone-full"
              [(ngModel)]="supportTicketAdmin.subject"
              placeholder="Subject"
            />

            <div style="padding-top: 10px">
              <h4>Reply Content</h4>
              <quill-editor
                #editor
                [(ngModel)]="supportTicketAdmin.htmlMessage"
                [style.height]="'300px'"
                [style.width]="'100%'"
                [modules]="editorConfig"
                (keydown.meta.enter)="sendSupportTicketAdmin()"
                (keydown.control.enter)="sendSupportTicketAdmin()"
              ></quill-editor>
            </div>
          </div>

          <div *ngIf="supportTicket.status == 'Close'">
            <h4>Response subject given</h4>
            <input
              class="border-field"
              [(ngModel)]="supportTicketAdmin.subject"
              placeholder="Subject"
            />
            <div style="padding-top: 10px">
              <h4>Reply given</h4>
              <i
                >At:
                {{
                  supportTicketAnswer?.createdAt | date : 'dd / MM / yyyy, h:mm'
                }}</i
              >
              <textarea
                class="border-field"
                style="height: 300px"
                >{{ supportTicketAnswer?.message }} </textarea
              >
            </div>
          </div>

          <div class="button-area">
            <div class="send-area-wrapper">
              <div *ngIf="supportTicket.status == 'Open'"></div>
              <div class="send-area">
                <button
                  *ngIf="supportTicket.status == 'Open'"
                  class="pinploy_button small_button red_button"
                  (click)="changeTicketStatus('close')"
                >
                  Close ticket
                </button>
                <button
                  *ngIf="supportTicket.status == 'Close'"
                  class="pinploy_button small_button green_button"
                  (click)="changeTicketStatus('open')"
                >
                  Reopen ticket
                </button>
                <button
                  *ngIf="supportTicket.status == 'Open'"
                  (click)="sendSupportTicketAdmin()"
                  class="pinploy_button"
                  [ngClass]="{ disabled: disableSubmitBtn() }"
                  [disabled]="disableSubmitBtn()"
                  [matTooltip]="'** Missing: Subject field or Reply content **'"
                  [matTooltipDisabled]="!disableSubmitBtn()"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="templates"
          *ngIf="supportTicket.status === 'Open'"
        >
          <div class="templates-wrapper">
            <div class="email-templates">
              <h3 style="text-align: center">Email Templates</h3>
              <div class="switch">
                <p *ngIf="isLanguageDK">Language: DK</p>
                <p *ngIf="!isLanguageDK">Language: EN</p>
                <mat-slide-toggle
                  *ngIf="isLanguageDK"
                  [(ngModel)]="isLanguageDK"
                  (click)="changeLanguage()"
                ></mat-slide-toggle>
                <mat-slide-toggle
                  *ngIf="!isLanguageDK"
                  [(ngModel)]="isLanguageDK"
                  (click)="changeLanguage()"
                ></mat-slide-toggle>
              </div>
              <input
                *ngIf="allPrioritizedEmailTemplates.length <= 0"
                class="border-field"
                type="text"
                placeholder="Search templates by question, cat or answer."
                (keyup)="filterEmailTemplates($event.target.value)"
              />

              <!-- Prioritize associated - Templates -->
              <div class="clear-button">
                <button
                  *ngIf="allPrioritizedEmailTemplates.length > 0"
                  (click)="clearSmartMatch('email')"
                  class="smart-btn"
                >
                  Clear smart match
                </button>
              </div>

              <!-- Prioritzed Email Templates -->
              <ng-container *ngIf="allPrioritizedEmailTemplates.length > 0">
                <div *ngIf="allPrioritizedEmailTemplates.length > 0">
                  <i>Suggested templates for this support ticket:</i>
                  <ul class="list-view">
                    <ng-container *ngIf="isLanguageDK">
                      <li
                        *ngFor="let temp of dkPrioritizedEmailTemplates"
                        (click)="selectAndInsertEmailTemp(temp)"
                      >
                        {{ temp.question }}
                      </li>
                    </ng-container>
                    <ng-container *ngIf="!isLanguageDK">
                      <li
                        *ngFor="let temp of engPrioritizedEmailTemplates"
                        (click)="selectAndInsertEmailTemp(temp)"
                      >
                        {{ temp.question }}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </ng-container>

              <!-- Filtered Email Templates -->
              <ng-container
                *ngIf="
                  allPrioritizedEmailTemplates.length === 0 &&
                  (!selectedEmailTemplateCategory ||
                    selectedEmailTemplateCategory === null ||
                    selectedEmailTemplateCategory === undefined) &&
                  searchEmpty
                "
              >
                <div>
                  <div>
                    <div
                      class="back-button"
                      (click)="smartMatchForEmailTemplate()"
                    >
                      <mat-icon>keyboard_backspace</mat-icon>
                      <p>Smart match</p>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <ul class="list-view">
                    <li
                      *ngFor="let category of emailTemplateCategories"
                      (click)="selectEmailTempCategory(category)"
                      style="list-style-type: none"
                    >
                      <ng-container *ngIf="isLanguageDK; else englishName">
                        &#9656;
                        <span style="padding-left: 10px">{{
                          category.dkName
                        }}</span>
                      </ng-container>
                      <ng-template #englishName>
                        &#9656;
                        <span style="padding-left: 10px">{{
                          category.enName
                        }}</span>
                      </ng-template>
                    </li>
                  </ul>
                </div>
              </ng-container>

              <ng-container>
                <div *ngIf="isLanguageDK && !searchEmpty">
                  <div>
                    <div
                      class="back-button"
                      (click)="navToCategories()"
                    >
                      <mat-icon>keyboard_backspace</mat-icon>
                      <p>Categories</p>
                    </div>
                    <mat-divider></mat-divider>
                  </div>

                  <ul class="list-view">
                    <li
                      *ngFor="let category of filteredEmailTemplates.dk"
                      (click)="selectAndInsertEmailTemp(category)"
                      style="list-style-type: none"
                    >
                      <ng-container *ngIf="category.countryCode == 'dk'">
                        &#8226;
                        <span style="padding-left: 10px">
                          {{ category.question }}
                        </span>
                      </ng-container>
                    </li>
                    <li
                      *ngIf="
                        filteredEmailTemplates.dk.length === 0 &&
                        allPrioritizedEmailTemplates?.length === 0 &&
                        !searchEmpty
                      "
                    >
                      No templates found
                    </li>
                  </ul>
                </div>

                <div *ngIf="!isLanguageDK && !searchEmpty">
                  <div>
                    <div
                      class="back-button"
                      (click)="navToCategories()"
                    >
                      <mat-icon>keyboard_backspace</mat-icon>
                      <p>Categories</p>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <ul class="list-view">
                    <li
                      *ngFor="let category of filteredEmailTemplates.eng"
                      (click)="selectAndInsertEmailTemp(category)"
                    >
                      <ng-container *ngIf="category.countryCode == 'eng'">
                        {{ category.question }}
                      </ng-container>
                    </li>
                    <li
                      *ngIf="
                        filteredEmailTemplates.eng.length === 0 &&
                        allPrioritizedEmailTemplates.length === 0 &&
                        !searchEmpty
                      "
                    >
                      No templates found
                    </li>
                  </ul>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="link-area">
            <h4>Links (add up to {{ MAX_LINKS_IN_EMAIL }})</h4>
            <div class="links">
              <div>
                <input
                  type="text"
                  class="border-field"
                  placeholder="Link btn text"
                  [(ngModel)]="linkBtnText"
                />
              </div>
              <div>
                <input
                  type="url"
                  class="border-field"
                  placeholder="URL"
                  [(ngModel)]="linkUrl"
                />
              </div>
              <div>
                <button
                  class="pinploy_button small_button"
                  style="width: 70px"
                  (click)="addLink()"
                >
                  Add link
                </button>
              </div>
            </div>
            <div
              *ngIf="addedLinks.length > 0"
              class="added-links"
            >
              <h5>Added links</h5>
              <div *ngFor="let link of addedLinks; let i = index">
                <p>
                  <mat-icon
                    style="cursor: pointer"
                    (click)="deleteLink(i)"
                    >delete</mat-icon
                  >
                  {{ link.btnText }} - ({{ link.url }})
                </p>
              </div>
            </div>
            <br />
          </div>
        </div>
      </body>
    </div>
  </div>
</div>
