import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { SupportTicketService } from '../../services/support.ticket.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { SupportTicket } from '../../models/supportTicket.model';

import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/app/services/state.service';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-support-ticket-list',
  templateUrl: './supportTicketList.component.html',
  styleUrls: ['./supportTicketList.component.scss'],
})
export class SupportTicketListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  supportTickets: SupportTicket[];
  isSupportTicketsOpen: boolean = null;
  columnsToDisplay: string[];
  dataSource: MatTableDataSource<SupportTicket>;

  private totalSubscription: Subscription;
  private readSubscription: Subscription;
  public currentlyViewedSupportTicketsSubscription: Subscription;

  constructor(
    public stateService: StateService,
    private toastr: ToastrService,
    private title: Title,
    private router: Router,
    private supportTicketService: SupportTicketService,
  ) {
    this.stateService.toggleWaitingPage(true);
  }

  ngOnInit() {
    this.fetchSupportTickerData();
    this.setMetaTitle();
    this.totalSubscription =
      this.stateService.totalActiveSupportTicketsComponentSource.subscribe(
        (changedTicket) => {
          const targetTicket = this.supportTickets.filter(
            (x) => x.id === changedTicket.id,
          )[0];
          targetTicket.status = changedTicket.body.status;
          this.toastr.info(
            `Support Ticket ${targetTicket.id} has been re-opened to by an admin`,
          );
        },
      );
    this.readSubscription =
      this.stateService.readActiveSupportTicketsComponentSource.subscribe(
        (changedTicket) => {
          const targetTicket = this.supportTickets.filter(
            (x) => x.id === changedTicket.id,
          )[0];
          targetTicket.status = changedTicket.body.status;
          this.toastr.info(
            `Support Ticket ${targetTicket.id} has been replied to by an admin`,
          );
        },
      );
  }

  subscribeToCurrentlyViewedSupportTickets() {
    this.currentlyViewedSupportTicketsSubscription =
      this.stateService.currentlyViewedSupportTicketsSource.subscribe({
        next: (tickets) => {
          if (tickets) {
            Object.entries(tickets).forEach(([ticketId, viewedBy]) => {
              const targetTicket = this.supportTickets?.filter(
                (x) => x.id === +ticketId,
              )[0];
              if (!targetTicket) return;
              // remove duplicate users from the list
              const viewedBySet = new Set();
              const viewedByUnique = viewedBy.filter((x) => {
                const duplicate = viewedBySet.has(x.id);
                viewedBySet.add(x.id);
                return !duplicate;
              });
              targetTicket.viewedBy = viewedByUnique;
            });
          }
        },
      });
  }

  setMetaTitle() {
    this.title.setTitle(`Support Tickets`);
  }

  fetchSupportTickerData() {
    this.columnsToDisplay = [
      'id',
      'status',
      'topic',
      'viewedBy',
      'email',
      'createdAt',
      'actions',
    ]; // missing actions

    this.supportTicketService.getSupportTickets().subscribe((res) => {
      if (res) {
        this.initDataSource(res);
        this.supportTickets = res;
        this.stateService.toggleWaitingPage(false);
        this.subscribeToCurrentlyViewedSupportTickets();
      }
    });
  }

  initDataSource(data: SupportTicket[], sort?: boolean) {
    this.dataSource = new MatTableDataSource<SupportTicket>(data);
    this.dataSource.paginator = this.paginator;
    this.sort.sort({ id: 'id', disableClear: false, start: 'desc' });
    this.dataSource.sort = this.sort;
  }

  applyFilter = (filterValue: string) =>
    (this.dataSource.filter = filterValue.trim().toLowerCase());

  goToSupportTicketDetail(id: number, event: MouseEvent) {
    const url = `/support-ticket/${id}`;
    const supportTicket = this.supportTickets.filter((x) => x.id === id)[0];

    if (event.metaKey || event.ctrlKey) {
      // Open in a new tab
      const urlWithBase = this.router.serializeUrl(
        this.router.createUrlTree([url]),
      );
      window.open(urlWithBase, '_blank');
    } else {
      // Navigate in the same tab
      this.router.navigate([url]);
    }
  }

  clearNotificationsOnSupportTicket(ticket: SupportTicket) {
    this.stateService.readActiveSupportTickets(ticket);
  }
  addNotificationBackOnSupportTicket(ticket: SupportTicket) {
    this.stateService.updateTotalActiveSupportTickets(ticket);
  }

  closeTicket(id: number, event: Event) {
    this.stateService.toggleWaitingPage(true);
    const ticket = this.supportTickets.find((x) => x.id === id);
    event.stopPropagation();
    this.stateService.toggleWaitingPage(false);
    if (ticket.status === 'Close') {
      ticket.status = 'Open';
      this.addNotificationBackOnSupportTicket(ticket);
    } else if (ticket.status === 'Open') {
      ticket.status = 'Close';
      this.clearNotificationsOnSupportTicket(ticket);
    }
    this.toastr.success(`Status changed to: ${ticket.status}`);
    this.supportTicketService
      .updateSupportTicketStatus({ status: ticket.status }, ticket.id)
      .subscribe((res) => {
        if (!res) {
          this.toastr.error(
            `Error changing status of support ticket ${ticket.id}`,
          );
          if (ticket.status === 'Close') {
            ticket.status = 'Open';
            this.addNotificationBackOnSupportTicket(ticket);
          } else if (ticket.status === 'Open') {
            ticket.status = 'Close';
            this.clearNotificationsOnSupportTicket(ticket);
          }
        }
      });
  }

  getStatusColor(status) {
    if (status === 'Open') {
      return 'rgb(60,201,7)';
    }
    return 'rgb(255,1,40)';
  }

  isTicketOpen(event) {
    this.isSupportTicketsOpen = !this.isSupportTicketsOpen;
    if (this.isSupportTicketsOpen) {
      const closedTickets = this.supportTickets.filter(
        (x) => x.status === 'Open',
      );
      this.initDataSource(closedTickets);
    } else if (this.isSupportTicketsOpen == false) {
      const openTickets = this.supportTickets.filter(
        (x) => x.status !== 'Open',
      );
      this.initDataSource(openTickets);
    }
  }

  resetShowAll() {
    this.isSupportTicketsOpen = null;
    this.initDataSource(this.supportTickets, false);
  }

  ngOnDestroy(): void {
    this.totalSubscription.unsubscribe();
    this.readSubscription.unsubscribe();
    this.currentlyViewedSupportTicketsSubscription.unsubscribe();
    this.title.setTitle(`Admin Dashboard`);
  }
}
