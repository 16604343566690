<div class="header_container">
  <div
    class="left"
    (click)="toggleNavbarOnPhone()"
  >
    <mat-icon class="list">view_quilt</mat-icon>
    <img
      class="logo"
      src="https://d10a6kin4dgxel.cloudfront.net/private/lp/webp/hh_logo_blue.webp"
      alt="Logo"
    />
  </div>

  <app-search-field></app-search-field>

  <div class="right-wrapper">
    <div class="online-users">
      <app-online-users />
    </div>
    <div class="column">
      <div class="mobile-hide">
        <h4>{{ loggedUser?.email }}</h4>
      </div>
      <div
        class="right"
        (click)="logout()"
      >
        <i class="material-icons">exit_to_app</i>
        <h4>Log out</h4>
      </div>
    </div>
  </div>
</div>
