import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'paginated-list',
  templateUrl: './paginatedList.component.html',
  styleUrls: ['./paginatedList.component.scss'],
})
export class PaginatedList implements AfterViewInit, OnChanges {
  @Input() data: any[];
  @Input() filterKeys: string[];
  @Input() moreDataAvailable: boolean = true;
  @Input() isDefaultSort: boolean = true;
  @Input() cards: boolean = false;

  @Output() dataRequested = new EventEmitter<void>();
  @Output() displayDataChanged = new EventEmitter<any[]>();

  filterString: string = '';
  filterData: any[] = [];
  displayData: any[] = [];
  entriesPerPage = 20;
  pageNumber = 1;

  constructor() {}

  ngAfterViewInit(): void {
    this.dataRequested.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data &&
      changes.data.previousValue?.length &&
      changes.data.currentValue.length < changes.data.previousValue.length
    ) {
      this.resetPagination();
    }
    this.applyFilter();
  }

  applyFilter(): void {
    const filterValue = this.filterString.trim().toLowerCase();
    if (this.filterKeys?.length > 0 && filterValue.length > 0) {
      this.resetPagination();
      this.filterData = this.data.filter((item) =>
        this.filterKeys.some((key) => {
          if (typeof item[key] === 'string') {
            // strings filtering
            return item[key].toLowerCase().includes(filterValue);
          } else if (Array.isArray(item[key])) {
            // tags filtering
            return (
              item[key].find((tag) =>
                tag.tag.toLowerCase().includes(filterValue),
              ) !== undefined
            );
          } else if (typeof item[key] === 'object' && item[key] !== null) {
            const _item = Object.values(item[key]).some((value) => {
              if (typeof value === 'string') {
                return value.toLowerCase().includes(filterValue);
              } else if (typeof value == 'number') {
                return value === parseInt(filterValue);
              }
            });
            return _item;
          } else {
            // numbers filtering
            return item[key] === parseInt(filterValue);
          }
        }),
      );
    } else {
      this.filterData = this.data.slice();
    }
    if (this.isDefaultSort) this.filterData.sort((a, b) => b.id - a.id);
    this.paginateAndEmit();
  }

  resetPagination(): void {
    this.pageNumber = 1;
  }

  sliceData(data: any[]): any[] {
    const start = (this.pageNumber - 1) * this.entriesPerPage;
    return data.slice(start, start + this.entriesPerPage);
  }

  paginateAndEmit(): void {
    const newData = this.sliceData(this.filterData);
    const maxPages = this.getMaxNumPages();

    this.displayData = newData;
    this.displayDataChanged.emit(this.displayData);

    if (this.pageNumber === maxPages && this.moreDataAvailable) {
      this.dataRequested.emit();
    }
  }

  prevPage(): void {
    if (this.pageNumber <= 1) {
      return;
    }

    this.pageNumber -= 1;
    this.paginateAndEmit();
  }

  nextPage(): void {
    const maxPages = this.getMaxNumPages();
    if (this.pageNumber > maxPages) {
      return;
    }

    this.pageNumber += 1;
    this.paginateAndEmit();
  }

  getMaxNumPages(): number {
    if (!this.filterData || this.filterData.length <= this.entriesPerPage) {
      return 1;
    }

    return Math.floor(this.filterData.length / this.entriesPerPage) + 1;
  }

  getPaginationString(): string {
    const length = this.filterData.length;
    if (!this.filterData || length === 0) {
      return '0 - 0 / 1';
    }

    const start = (this.pageNumber - 1) * this.entriesPerPage + 1;
    const end =
      this.pageNumber <= this.getMaxNumPages()
        ? this.entriesPerPage * this.pageNumber
        : start - 1 + (length % this.entriesPerPage);
    return `${start} - ${Math.min(end, length)} / ${length}`;
  }

  scrollToTop(): void {
    const documentRef = document.getElementsByClassName('pagination');
    documentRef[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
