<div
  class="spinner_container"
  *ngIf="isLoading"
>
  <mat-spinner class="tagging-local-spinner"></mat-spinner>
</div>

<div
  class="dialog_container"
  *ngIf="!isLoading"
>
  <h2>Date negotiation for offer {{ offerId }}</h2>

  <div class="card-box">
    <app-user-card
      [title]="'Taskowner'"
      [user]="taskOwner"
    ></app-user-card>
    <app-user-card
      [title]="'Handyhander'"
      [user]="handyhander"
    ></app-user-card>
  </div>

  @if (rounds.length === 0) {
  <h2>No negotiations on this offer.</h2>
  } @else { @for (round of rounds; track $index) {
  <mat-expansion-panel
    class="proposal"
    [ngClass]="{
      green: round.phase === 'agreement' && $index === rounds.length - 1
    }"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>
          <b>{{ round.user.firstName }}</b>

          <span>
            sent a {{ round.phase }} at
            {{ round.createdAt | date : 'HH:MM, dd/MM/yyyy, (EEEE)' }}
          </span>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="suggestions">
      @if (round.phase === 'proposal') { @if (round.data.date) {
      <p class="proposal-title">For following date:</p>
      <p class="dates">
        <mat-icon class="icon">calendar_today</mat-icon>
        {{ round.data.date | date : 'dd/MM/yyyy, (EEEE)' }}
      </p>
      } @else if (round.data.dates) {
      <p class="proposal-title">For following dates:</p>
      @for (date of round.data.dates; track $index) {
      <p class="dates">
        <mat-icon class="icon">calendar_today</mat-icon>
        {{ date | date : 'dd/MM/yyyy, (EEEE)' }}
      </p>
      } } } @else if (round.phase === 'counter') {
      <p class="proposal-title">Countered date:</p>
      <p class="dates">
        <mat-icon class="icon">calendar_today</mat-icon>
        {{ round.data.date | date : 'dd/MM/yyyy, (EEEE)' }}
      </p>
      } @else if (round.phase === 'agreement') {
      <p class="proposal-title">Accepted date:</p>
      <p class="dates">
        <mat-icon class="icon">calendar_today</mat-icon>
        {{ round.data.date | date : 'dd/MM/yyyy, (EEEE)' }}
      </p>
      }
    </div>
  </mat-expansion-panel>
  } }
</div>
