<div class="users_currently_viewing_container">
  @for (user of usersCurrentlyViewing; track $index) {
  @if (user.profileImg) {
    <div class="profile_image">
      <img loading="lazy" [matTooltip]="user.firstName"
        src="{{ user.profileImg }}" alt="Profile Image" />
    </div>
  } @else {
  <mat-icon class="profile_icon" [matTooltip]="user.firstName">
    account_circle
  </mat-icon>
  } }
</div>