import {
  Component,
  OnDestroy,
  OnInit,
  ViewChildren,
  ElementRef,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { ConversationService } from 'src/app/services/conversation.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TaskService } from 'src/app/services/task.service';
import { OfferService } from 'src/app/services/offer.service';
import { Task } from 'src/app/models/task.model';
import { Offer } from 'src/app/models/offer.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { MiscService } from 'src/app/services/misc.service';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from 'src/app/components/dialogs/questionDialog/questionDialog.component';
@Component({
  selector: 'app-task-private-conversation',
  templateUrl: './taskPrivateConversation.component.html',
  styleUrls: ['./taskPrivateConversation.component.scss'],
})
export class TaskPrivateConversationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChildren('messageContainer') messageContainer: QueryList<ElementRef>;
  conversation: any;
  task: Task;
  offer: Offer;
  taskOwner: User;
  handyhander: User;

  searchTerm: string;

  chosenBlocker: any;
  blocked: boolean = false;

  constructor(
    private miscService: MiscService,
    private title: Title,
    private userService: UserService,
    private taskService: TaskService,
    private offerService: OfferService,
    private toastr: ToastrService,
    private conversationService: ConversationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialog,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.conversationService.getConversationByTaskId(params.taskId).subscribe(
        (convRes) => {
          if (convRes) {
            this.conversation = convRes;
            this.sortMessagesByDates();

            // Get the corosponding task
            this.taskService.getTaskById(params.taskId).subscribe((taskRes) => {
              this.task = taskRes;
              this.taskOwner = this.task.user;
              this.setMetaTitle();
            });

            // Get the corosponding offer
            this.offerService
              .getOfferById(this.conversation.offerId)
              .subscribe((offerRes) => {
                this.offer = offerRes;

                this.userService
                  .getUserProfile(this.offer.userId)
                  .subscribe((handyhanderRes) => {
                    this.handyhander = handyhanderRes;
                  });
              });
          }
        },
        (err) => {
          this.toastr.error('Could not find conversation for this task.');
          this.router.navigate(['search']);
        },
      );
      if (params.search) {
        this.searchTerm = params.search;
      }
    });
  }

  ngAfterViewInit(): void {
    this.messageContainer.changes.subscribe(() => {
      this.performSearch();
    });
  }

  sortMessagesByDates() {
    this.conversation.messages.forEach((message) => {
      message.sentAt = new Date(message.createdAt);
      this.miscService
        .getMediaByResourceAndId('message', message.id)
        .subscribe((res) => {
          message.media = res;
        });
    });

    this.conversation.messages.sort((a, b) => {
      return a.sentAt - b.sentAt;
    });
  }

  setMetaTitle() {
    this.title.setTitle(`Direct messages on task: "${this.task.title}"`);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      // Invalid date string, return an error message or handle it as needed
      return 'Invalid Date';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}-${month}-${year}, time: ${hours}:${minutes}:${seconds}`;
  }

  blockConversationAsUser() {
    if (this.conversation.blockedByUserId) {
      if (this.conversation.blockedByUserId == this.taskOwner.id) {
        this.conversationService
          .unblockConversationAsUser(
            this.conversation.offerId,
            this.taskOwner.id,
          )
          .subscribe(
            (res) => {
              this.conversation.blockedByUserId = null;
            },
            (err) => {
              this.toastr.error('Could not unblock conversation.');
              console.log(err);
            },
          );
      } else {
        this.conversationService
          .unblockConversationAsUser(
            this.conversation.offerId,
            this.handyhander.id,
          )
          .subscribe(
            (res) => {
              this.conversation.blockedByUserId = null;
            },
            (err) => {
              this.toastr.error('Could not unblock conversation.');
              console.log(err);
            },
          );
      }
    } else {
      this.conversationService
        .blockConversationAsUser(this.conversation.offerId, this.chosenBlocker)
        .subscribe(
          (res) => {
            this.conversation.blockedByUserId = this.chosenBlocker;
          },
          (err) => {
            this.toastr.error('Could not block conversation.');
            console.log(err);
          },
        );
    }
  }

  performSearch() {
    if (this.searchTerm) {
      const searchTerm = this.searchTerm.toLowerCase();
      let firstMatch = null;

      if (!searchTerm) {
        this.messageContainer.forEach((messageElement) => {
          messageElement.nativeElement.classList.remove('highlighted');
        });
        return;
      }

      this.messageContainer.forEach((messageElement) => {
        const message = messageElement.nativeElement.textContent.toLowerCase();

        if (message.includes(searchTerm)) {
          messageElement.nativeElement.classList.add('highlighted');

          if (!firstMatch) {
            firstMatch = messageElement;
          }
        } else {
          messageElement.nativeElement.classList.remove('highlighted');
        }
      });

      if (firstMatch) {
        firstMatch.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  sendSystemWarning() {
    const dialog = this.dialogRef.open(QuestionDialogComponent, {
      data: {
        title: 'Send system warning',
        description: 'Are you sure you want to send a system reminder?',
      },
    });
    dialog.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.conversationService
            .sendSystemWarning(this.conversation.offerId)
            .subscribe({
              next: () => {
                this.ngOnInit();
                this.toastr.success('System warning sent');
              },
              error: (err) => {
                this.toastr.error('Could not send system warning');
                console.error(err);
              },
            });
        }
      },
    });
  }

  checkForMbpay(text: string) {
    if (text.match(/mobile ?pay/gi)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }
}
