<div>
  <h3 class="header">Users active offers</h3>
</div>
<div class="dialog_container">
  <div class="filteringOptions">
    <div class="search_options">
      <div #status>
        <mat-form-field>
          <mat-label>Filter by status</mat-label>
          <mat-select
            [(value)]="searchStatus"
            (selectionChange)="selectedStatusChange($event)"
          >
            <mat-option
              *ngFor="let status of statusOptions"
              [value]="status.value"
            >
              {{ status.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        id="date"
        #date
      >
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Start date"
              (dateChange)="searchStartDateChange($event)"
            />
            <input
              matEndDate
              placeholder="End date"
              (dateChange)="searchEndDateChange($event)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input
            matInput
            placeholder="Enter a search query"
            [(ngModel)]="searchQuery"
            (ngModelChange)="searchQueryChange($event)"
            (keyup.enter)="searchOffers(true)"
          />
        </mat-form-field>
      </div>

      <div
        style="align-content: center"
        #reset
      >
        <button
          mat-icon-button
          (click)="resetFilter()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </div>

      <div
        style="display: flex; align-items: center"
        #searchButton
      >
        <button
          mat-fab
          extended
          color="primary"
          (click)="searchOffers(true)"
        >
          <mat-icon>search</mat-icon>
          Search
        </button>
      </div>
    </div>
  </div>

  <div>
    <mat-paginator
      [length]="userActiveOffers.length"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20]"
      (page)="onPageChange($event)"
    >
    </mat-paginator>

    <div class="task_card_container_grid">
      <div
        class="dialog_row_container"
        *ngFor="let offer of paginatedOffers; let i = index"
      >
        <div class="dialog_column_container">
          <div class="task_card_container">
            <app-task-card
              [offer]="offer"
              [task]="offer.task"
              (closeDialogEvent)="closeDialogEvent($event)"
            ></app-task-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
