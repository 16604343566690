import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/services/user.service";
import { AuthService } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import { StateService } from "src/app/services/state.service";
import { SocketService } from "src/app/services/socket.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === "Enter") {
      this.login();
    }
  }

  constructor(
    private stateService: StateService,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private storage: StorageService,
    private authService: AuthService,
    private socketService: SocketService,
  ) {}

  ngOnInit() {
    this.email = "";
    this.password = "";
  }

  isBtnDisabled(): boolean {
    return (
      !this.email ||
      this.email.trim().length === 0 ||
      !this.password ||
      this.password.trim().length === 0
    );
  }

  login() {
    if (this.isBtnDisabled()) {
      return;
    }

    const data = { email: this.email, password: this.password };

    this.authService.login(data).subscribe(
      (res) => {
        if (!res["id"]) {
          return;
        }
        this.stateService.toggleWaitingPage(true);
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(res["id"]);
        if (decodedToken.role !== "admin") {
          this.stateService.toggleWaitingPage(false);
          return this.toastr.error("Access denied");
        }


        this.storage.token = res["id"];
        this.socketService.connectSocket();

        this.userService.getUserProfile(res.userId).subscribe((res) => {
          this.storage.user = res;
          this.router.navigate(["/"]);
          this.stateService.toggleWaitingPage(false);
        });
        },
      () => {
        this.toastr.error(
          "Login failed. Please make sure you have entered the right credentials"
        );
        this.stateService.toggleWaitingPage(false);
      }
    );

  }
}
