<div>
  <h3 class="header">Users penalties</h3>
</div>
<div class="dialog_container">
  <mat-tab-group>
    <mat-tab
      label="Applied penalties "
      *ngIf="userAppliedPenalties.length !== 0"
    >
      <div
        class="dialog_row_container"
        *ngFor="let penalty of userAppliedPenalties; let i = index"
      >
        <div class="dialog_column_container">
          <div class="penalty_card_container">
            <app-penalty-card [penalty]="penalty"></app-penalty-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      label="Pending Penalties"
      *ngIf="userPendingPenalties.length !== 0"
    >
      <div
        class="dialog_row_container"
        *ngFor="let penalty of userPendingPenalties; let i = index"
      >
        <div class="dialog_column_container">
          <div class="penalty_card_container">
            <app-penalty-card [penalty]="penalty"></app-penalty-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      label="Canceled Penalties"
      *ngIf="userCanceledPenalties.length !== 0"
    >
      <div
        class="dialog_row_container"
        *ngFor="let penalty of userCanceledPenalties; let i = index"
      >
        <div class="dialog_column_container">
          <div class="penalty_card_container">
            <app-penalty-card [penalty]="penalty"></app-penalty-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      label="Completed Penalties"
      *ngIf="userCompletedPenalties.length !== 0"
    >
      <div
        class="dialog_row_container"
        *ngFor="let penalty of userCompletedPenalties; let i = index"
      >
        <div class="dialog_column_container">
          <div class="penalty_card_container">
            <app-penalty-card [penalty]="penalty"></app-penalty-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      label="Transfered Penalties"
      *ngIf="userTransferedPenalties.length !== 0"
    >
      <div
        class="dialog_row_container"
        *ngFor="let penalty of userTransferedPenalties; let i = index"
      >
        <div class="dialog_column_container">
          <div class="penalty_card_container">
            <app-penalty-card [penalty]="penalty"></app-penalty-card>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
