<mat-card [ngClass]="{ deleted: note.deletedAt != undefined || null }">
  <div
    class="card_header"
    *ngIf="note"
  >
    <h5 class="strong">Id: {{ note.id }}</h5>
    <h6>Note written by admin: {{ note.admin.firstName }}</h6>
  </div>
  <div class="card_body">
    <div
      class="card_row"
      *ngIf="note"
    >
      <p class="strong">Note</p>
      <p [innerHTML]="note.comment | linkify"></p>
    </div>
    <div
      class="card_row"
      *ngIf="note.deletedAt"
    >
      <p class="strong">Deleted at:</p>
      <p>{{ formatDate(note.deletedAt) }}</p>
    </div>
  </div>
  <div
    class="button-container"
    *ngIf="!note.deletedAt"
  >
    <button
      (click)="deleteNote()"
      class="pinploy_button small_button red_button"
    >
      Delete
    </button>
  </div>
</mat-card>
