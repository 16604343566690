<div class="support-ticket-container">
  <div class="intro">
    <div>
      <h1>Support Tickets</h1>
    </div>
    <div class="header">
      <div>
        @switch (isSupportTicketsOpen){ @case (true){
        <h3>Showing: open only</h3>
        } @case (false){
        <h3>Showing: closed only</h3>
        } @case (null){
        <h3>Showing: all</h3>
        } @default{ } }
        <label class="switch">
          <mat-slide-toggle color="primary" (change)="isTicketOpen($event)"
            [ngModel]="isSupportTicketsOpen"></mat-slide-toggle>
        </label>
        <a (click)="resetShowAll()" class="clickable-link">
          <p>(Reset)</p>
        </a>
      </div>
      <div class="filter">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="body">
    <mat-table [dataSource]="dataSource" matSort matSortDirection="desc">
      <!-- ID column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          ID
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.id }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Status
        </th>
        <td mat-cell [ngStyle]="{ color: getStatusColor(row.status) }" *matCellDef="let row">
          {{ row.status | uppercase }}
        </td>
      </ng-container>

      <!-- Email column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Email
        </th>

        <td mat-cell *matCellDef="let row">
          {{ row.email }}
        </td>
      </ng-container>

      <!-- Topic column -->
      <ng-container matColumnDef="topic">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Topic
        </th>

        <td mat-cell *matCellDef="let row" style="width: 100% !important">
          <span>
            <p>{{ row.topic }}</p>
            <i>- "{{ row.detailConcern }}"</i>
          </span>
        </td>
      </ng-container>

      <!-- Currently viewed by users column (viewedBy) -->
      <ng-container matColumnDef="viewedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Viewing
        </th>
        <td mat-cell *matCellDef="let row">
          <app-users-currently-viewing [usersCurrentlyViewing]="row.viewedBy" />
        </td>
      </ng-container>

      <!-- createdAt column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          createdAt
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.createdAt | date : 'HH:mm, dd/MM/yyyy' }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="justify-content: center">
          Actions
        </th>
        <td mat-cell *matCellDef="let row" style="justify-content: center">
          <button *ngIf="row.status === 'Open'" mat-button class="pinploy_button small_button red_button margin"
            (click)="closeTicket(row.id, $event)">
            Close ticket
          </button>

          <button *ngIf="row.status === 'Close'" mat-button class="pinploy_button small_button margin"
            (click)="closeTicket(row.id, $event)">
            Reopen ticket
          </button>
        </td>
      </ng-container>

      <tr style="width: 100%" mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr style="width: 100%" mat-row *matRowDef="let row; columns: columnsToDisplay"
        (click)="goToSupportTicketDetail(row.id, $event)"></tr>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
  <!-- <app-currently-viewed-support-tickets  /> -->
</div>