<div
  class="dialog_container"
  style="max-height: 80vh"
>
  <!-- If no stored credits has been creditted or debitted-->
  <div
    class="no-credit"
    *ngIf="
      storedCredits == null ||
      storedCredits == undefined ||
      storedCredits.length == 0
    "
  >
    <h2>No stored credit history...</h2>
    <h4>As of yet!</h4>
  </div>
  <div
    class="no-credit"
    *ngIf="
      storedCredits !== null ||
      storedCredits !== undefined ||
      storedCredits.length !== 0
    "
  >
    <h2>Stored credits history</h2>
  </div>

  <div
    class="table_container"
    *ngIf="storedCredits"
  >
    <table
      mat-table
      [dataSource]="storedCredits"
    >
      <!-- Credit id Column -->
      <ng-container matColumnDef="Credit id">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Credit Id
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          <div *ngIf="credit.id != null">
            {{ credit.id }}
          </div>
          <div *ngIf="credit.id == null">Current total:</div>
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="Type">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Type
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          <div *ngIf="credit.type == 'usedDebit'">Spent credit</div>
          <div *ngIf="credit.type == 'retainedDebit'">
            Connection fee "lost" from cancelling
          </div>
          <div *ngIf="credit.type == 'cancellationCredit'">
            Credit from cancelled task
          </div>
          <div *ngIf="credit.type == 'priceDecreaseCredit'">
            Credit from decreased task
          </div>
          <div *ngIf="credit.type == 'referralCredit'">
            Credit from refer a friend
          </div>
          <div *ngIf="credit.type == 'expiredTaskCredit'">
            Credit from expired task
          </div>
          <div *ngIf="credit.type == 'expiredDebit'">
            Credit that expired. (Credit id: {{ credit.sourceCreditId }})
          </div>
          <div *ngIf="credit.type == 'withdrawnDebit'">
            Credit withdrawn from the platform
          </div>
          <div *ngIf="credit.type == 'reversed'">
            Credits reversed on debit entry
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Transaction Type">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Transaction Type
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          <div *ngIf="credit.transactionType == 'credit'">Credit</div>
          <div *ngIf="credit.transactionType == 'debit'">Debit</div>
        </td>
      </ng-container>

      <!-- Offer Column -->
      <ng-container matColumnDef="Offer">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Offer
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          <a href="/offers/{{ credit.offerId }}">
            {{ credit.offerId }}
          </a>
          @if (credit.markedAsExpired) {
          <span class="expired">
            <b style="color: red; font-size: large">
              EXPIRED AT: {{ credit.expiresAt | date : 'dd/MM/yyyy' }}
            </b>
          </span>
          }
        </td>
      </ng-container>

      <!-- Task Column -->
      <ng-container matColumnDef="Task">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Task
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          <a
            *ngIf="credit.task?.id"
            href="/tasks/{{ credit.task?.id }}"
          >
            {{ credit.task.id }}
          </a>
        </td>
      </ng-container>

      <!-- Created at Column -->
      <ng-container matColumnDef="Created at">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Created at
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          {{ credit.createdAt | date : 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="Amount">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Amount
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          <div *ngIf="credit.transactionType == 'credit'">
            + {{ credit.amount / 100 }} kr.
          </div>
          <div *ngIf="credit.transactionType == 'debit'">
            - {{ credit.amount / 100 }} kr.
          </div>
          <!-- <div *ngIf="credit.transactionType == 'credit' && credit.reversed">
            + {{ credit.amount / 100 }} ({{ credit.reversed / 100 }} reversed)
            kr.
          </div>
          <div *ngIf="credit.transactionType == 'debit' && credit.reversed">
            - {{ credit.amount / 100 }} ({{ credit.reversed / 100 }} reversed)
            kr.
          </div> -->
        </td>
      </ng-container>

      <!-- Running total Column -->
      <ng-container matColumnDef="Remaining">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Running total
        </th>
        <td
          mat-cell
          *matCellDef="let credit"
        >
          {{ credit.remaining }} kr.
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.strikethrough]="
          (checkExpiringDate(row.expiresAt) && !row.amountUsed) || row.deletedAt
        "
        [ngClass]="{
          'credit-row': row.transactionType === 'credit',
          'debit-row': row.transactionType === 'debit'
        }"
      ></tr>
    </table>
    <mat-divider></mat-divider>
    <p>Current balance: {{ runningTotal / 100 }} kr.</p>
  </div>
</div>
