import { User } from './user.model';
import { Offer } from './offer.model';
import { Comment } from './comment.model';
import { Tag } from './tag.model';

export class Task {
  id: number;
  title: string;
  description: string;
  isEmergency: boolean;
  address: string;
  city: string;
  zipCode: string;
  lat: number;
  lng: number;
  budget: number;
  dueDate: string;
  dateType: string;
  status: string;
  images?: string[];
  tag?: Tag[];
  applicantIds: number[];
  acceptedOffer: Offer;
  completedOffer: Offer;
  conversationId: number;
  tagUpdated?: string;
  userId: number;
  user: User;

  aiGeneratedTitle: Date;

  offers: Offer[];
  comments: Comment[];

  createdAt: string;
  updatedAt: string;
  canceledAt: string;
  toolsNeeded: boolean;

  boostedAt: Date;

  extended: any;

  constructor(data: any = null) {
    Object.assign(this, data);

    if (!this.user) {
      this.user = new User();
    }
    if (!this.offers) {
      this.offers = [];
    }
    if (!this.images) {
      this.images = [];
    }
    if (!this.comments) {
      this.comments = [];
    }
    if (!this.applicantIds) {
      this.applicantIds = [];
    }
  }
}
