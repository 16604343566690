import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-online-users',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './online-users.component.html',
  styleUrl: './online-users.component.scss'
})
export class OnlineUsersComponent implements OnInit, OnDestroy {
  onlineAdmins$: Subscription;
  onlineAdmins: any[];

  constructor(
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.onlineAdmins$ = this.stateService.onlineAdminsSource.subscribe({
      next: (admins) => {
        this.onlineAdmins = admins;
      }
    });
  }

  ngOnDestroy() {
    this.onlineAdmins$.unsubscribe();
  }
}
