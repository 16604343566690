import {
  Component,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { isBefore, format } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { QuestionDialogComponent } from '../questionDialog/questionDialog.component';
import { QuestionDialogData } from '../questionDialog/questionDialog.component';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'remove-profile-pic-dialog',
  templateUrl: 'removeProfilePicDialog.component.html',
  styleUrls: ['../dialogs.scss', 'removeProfilePicDialog.component.scss'],
})
export class RemoveProfilePicDialogComponent implements OnInit {
  public userData: User;
  showOrHideImageBoolean: boolean = true;

  constructor(
    private stateService: StateService,
    public dialogRef: MatDialogRef<RemoveProfilePicDialogComponent>,
    public dialog: MatDialog,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to ban user dialog.');
    }
  }
  ngOnInit(): void {
    this.fetchUserData();
  }

  fetchUserData(): void {
    this.userService
      .getUserProfile(this.data.userId)
      .subscribe((result) => (this.userData = result));
  }

  getDate(timestamp: string) {
    return format(new Date(timestamp), 'dd-MM-yyyy');
  }

  showOrHideImage() {
    this.showOrHideImageBoolean = !this.showOrHideImageBoolean;
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }

  confirmProfilePicRemoval() {
    const data: QuestionDialogData = {
      title: 'Remove profile pic',
      description:
        'Are you sure? Profile pic will be removed, and user will (conditionally) receive an email notification.',
      showCheckBox: true,
      checkBoxQuestion: 'Notify user via email?',
    };
    const dialog = this.dialog.open(QuestionDialogComponent, {
      width: '500px',
      data: data,
    });
    dialog.afterClosed().subscribe((res) => {
      console.log(res);
      if (res.res === 'yes' && res.checkBoxStatus) {
        this.removeProfilePictureAndNotify(res.checkBoxStatus);
        this.dialogRef.close('Yes');
      } else {
        this.dialogRef.close('No');
      }
    });
  }

  removeProfilePictureAndNotify(notify: boolean) {
    this.stateService.toggleWaitingPage(true);
    this.userService
      .removeProfilePictureAndNotify(this.userData.id, notify)
      .subscribe((res) => {
        if (res) {
          this.dialogRef.close();
          this.stateService.toggleWaitingPage(false);
          this.toastr.success(
            'Profile pic removed and user has been notified via email.',
            'Success',
          );
        } else {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error('User has no profile picture', 'Error');
        }
      }),
      (err) => console.error(err);
  }
}
